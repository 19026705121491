import { IBankIdLoginRequest, IBankIdPollRequest } from './types';

// tslint:disable:max-classes-per-file
export class UpdateLoginStatus {
    static readonly type = '[authorization] check login status';
    constructor(public agreementId: string, public wizardVersion: string) {}
}
export class LoginViaBankId {
    static readonly type = '[authorization] login via bankid';
    constructor(public form: IBankIdLoginRequest) {}
}

export class PollBankIDLoginStatus {
    static readonly type = '[authorization] poll bankid login status';
    constructor(public form: IBankIdPollRequest) {}
}

export class RefreshToken {
    static readonly type = '[authorization] refresh token';
}

export class Logout {
    static readonly type = '[authorization] logout';
}
