import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AutosizeModule } from 'ngx-autosize';
import { NgxCurrencyModule } from 'ngx-currency';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RenderForEnabledScreensDirectiveModule } from 'projects/shared/directives/enabled-screens/render-for-enabled-screens-directive.module';
import { NumberFormatDirectiveModule } from 'projects/shared/directives/number-format/number-format-directive.module';
import { ButtonComponent } from 'projects/tools/src/lib/shared/components/button/button.component';
import { CheckboxInputComponent } from 'projects/tools/src/lib/shared/components/checkbox-input/checkbox-input.component';
import { DrawerComponent } from 'projects/tools/src/lib/shared/components/drawer/drawer.component';
import { LoaderComponent } from 'projects/tools/src/lib/shared/components/loader/loader.component';
import { ModalComponent } from 'projects/tools/src/lib/shared/components/modal/modal.component';
import { NumberInputComponent } from 'projects/tools/src/lib/shared/components/number-input/number-input.component';
import { PopoverComponent } from 'projects/tools/src/lib/shared/components/popover/popover.component';
import { SelectInputComponent } from 'projects/tools/src/lib/shared/components/select-input/select-input.component';
import { TextInputComponent } from 'projects/tools/src/lib/shared/components/text-input/text-input.component';
import { TooltipComponent } from 'projects/tools/src/lib/shared/components/tooltip/tooltip.component';
import { ValidationErrorsComponent } from 'projects/tools/src/lib/shared/components/validation-errors/validation-errors.component';
import { TooltipDirective } from 'projects/tools/src/lib/shared/directives/tooltip.directive';
import { SafePipe } from 'projects/tools/src/lib/shared/pipes/safe.pipe';

import { DatepickerInputComponent } from './shared/components/datepicker-input/datepicker-input.component';
import { ModalInsertLinkComponent } from './shared/components/modal-insert-link/modal-insert-link.component';
import { TimepickerInputComponent } from './shared/components/timepicker-input/timepicker-input.component';

@NgModule({
  declarations: [
    TextInputComponent,
    DatepickerInputComponent,
    TimepickerInputComponent,
    NumberInputComponent,
    SelectInputComponent,
    CheckboxInputComponent,
    LoaderComponent,
    ButtonComponent,
    ValidationErrorsComponent,
    ModalComponent,
    DrawerComponent,
    PopoverComponent,
    TooltipComponent,
    TooltipDirective,
    SafePipe,
    ModalInsertLinkComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    OverlayModule,
    PortalModule,
    MatDatepickerModule,
    MatFormFieldModule,
    NgxMaterialTimepickerModule,
    MatNativeDateModule,
    InfiniteScrollModule,
    NgxCurrencyModule,
    NumberFormatDirectiveModule,
    RenderForEnabledScreensDirectiveModule,
    AutosizeModule,
    NgSelectModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    OverlayModule,
    PortalModule,
    MatDatepickerModule,
    MatFormFieldModule,
    NgxMaterialTimepickerModule,
    MatNativeDateModule,
    InfiniteScrollModule,
    NgxCurrencyModule,
    TextInputComponent,
    DatepickerInputComponent,
    TimepickerInputComponent,
    NumberInputComponent,
    SelectInputComponent,
    CheckboxInputComponent,
    LoaderComponent,
    ButtonComponent,
    ValidationErrorsComponent,
    ModalComponent,
    DrawerComponent,
    PopoverComponent,
    TooltipComponent,
    TooltipDirective,
    SafePipe,
    ModalInsertLinkComponent,
    NgSelectModule,
  ],
})
export class ToolsModule { }
