import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ApiService } from 'projects/tools/src/lib/core/services/api.service';
import { ConstantsService } from 'projects/tools/src/lib/core/services/constants.service';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
export interface IJwtInfo {
    iss: 'core' | 'crm' | 'micro';
    userId: string;
    coreUserId?: number;
    name: string;
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    segment: string;
    partner: string;
    tenantId: string;
    lawfirmId: string;
    roles: Record<string, string>;
}

@Injectable({ providedIn: 'root' })
export class LoggedService {
    private jwtInfo$: BehaviorSubject<IJwtInfo> = new BehaviorSubject(null);

    constructor(private apiService: ApiService, private constantService: ConstantsService) {}

    public checkJwt$(): Observable<IJwtInfo | any> {
        if (this.jwtInfo) {
            return of(this.jwtInfo);
        } else {
            return this.apiService.get$(environment.productModule, '/jwt-check').pipe(
                map((jwtInfo: IJwtInfo) => {
                    this.jwtInfo$.next(jwtInfo);

                    return jwtInfo;
                }),
                catchError((error) => {
                    return throwError(error);
                }),
            );
        }
    }

    public refresh$(): Observable<void> {
        const url = `${this.constantService.coreApiUrl}/jwts/refresh`;

        return this.apiService.post$(null, url, {});
    }

    public get jwtInfo(): IJwtInfo {
        return this.jwtInfo$.getValue();
    }

    public clearJwtInfo(): void {
        return this.jwtInfo$.next(null);
    }

    public get redirectUrl(): string {
        const country = this.apiService.getCountry();
        const source = this.constantService.countryAuthSource[country || this.constantService.defaultCountry];
        let redirectUrl;

        switch (source) {
            case 'core':
                redirectUrl = this.constantService.coreAuthUrlAdmin;
                break;
            case 'crm':
                redirectUrl = this.constantService.crmAuthUrl;
        }

        return redirectUrl;
    }

    public logout(): void {
        location.href = this.apiService.populateUrl('', this.redirectUrl);
    }
}
