import { NgModule } from '@angular/core';

import { RenderForEnabledScreensDirective } from './render-for-enabled-screens.directive';

@NgModule({
  declarations: [RenderForEnabledScreensDirective],
  exports: [RenderForEnabledScreensDirective],
})
export class RenderForEnabledScreensDirectiveModule {
}
