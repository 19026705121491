import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import {
    IAgreementInformation,
    IAgreementSummarySection,
    IDocumentElement,
    IDocumentElementControl,
    IDocumentSection,
    IEntryResponse,
    IWizard,
    IWizardAnswer,
    IWizardRuleSet,
    IWizardVersion,
    IWizardVersionSchema,
} from 'projects/mission-control/src/app/modules/logged/wizard-builder/store/types';
import { ApiService } from 'projects/tools/src/lib/core/services/api.service';
import { IPagination } from 'projects/tools/src/lib/shared/interfaces/pagination.interface';
import { AsyncSubject, Observable, ReplaySubject, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { IAgreementStatusInformation, IContinueAgreementInfo } from './types';

@Injectable({
    providedIn: 'root',
})
export class QuestionnaireService {
    public tree: IDocumentSection;
    public advancedControlCache: Record<string, ReplaySubject<any>> = {};

    constructor(private apiService: ApiService, private http: HttpClient) {}

    public clearTree(): void {
        this.tree = null;
    }

    public getStartTree$(rootSectionID: string): Observable<IDocumentSection> {
        return this.apiService.get$(environment.wizardBuilder, `/wizard_sections/${rootSectionID}`).pipe(
            switchMap((section: IDocumentSection) => {
                this.tree = section;

                return section.hasChildren ? this.getChildSections$(this.tree) : this.getChildElements$(this.tree);
            }),
            switchMap(() => of(this.tree)),
        );
    }

    public getChildSections$(parentSection: IDocumentSection): Observable<IDocumentSection[]> {
        return this.apiService
            .get$(environment.wizardBuilder, `/wizard_sections`, {
                parent: parentSection.id,
                perPage: 9999,
            })
            .pipe(
                map((data: IPagination<IDocumentSection>) => data.result),
                tap((sections: IDocumentSection[]) => (parentSection.fetchedWizardSections = sections)),
            );
    }

    public getChildElements$(parentSection: IDocumentSection, fetchedElements: IDocumentElement[] = []): Observable<IDocumentElement[]> {
        const elementsFound = fetchedElements.filter((el) => (el.wizardSection.id || el.wizardSection.split('/').pop()) === parentSection.id);

        if (elementsFound.length) {
            return of(elementsFound).pipe(
                tap((elements: IDocumentElement[]) => {
                    parentSection.hasControlsInElements = elements.some((elem) => this.doesElementContainsControl(elem));
                    parentSection.fetchedWizardElements = elements;
                }),
            );
        }

        return this.apiService.get$(environment.wizardBuilder, `/wizard_sections/${parentSection.id}/wizard_elements`, { perPage: 9999 }).pipe(
            map((data: IPagination<IDocumentElement>) => data.result),
            tap((elements: IDocumentElement[]) => {
                parentSection.hasControlsInElements = elements.some((elem) => this.doesElementContainsControl(elem));
                parentSection.fetchedWizardElements = elements;
            }),
        );
    }

    public getWizardVersion$(id: string): Observable<IWizardVersion> {
        return this.apiService.get$(environment.wizardBuilder, `/wizard_versions/${id}`);
    }

    public getWizard$(wizardId: string): Observable<IWizard> {
        return this.apiService.get$(environment.wizardBuilder, `/wizards/${wizardId}`);
    }

    public getWizardBySlug$(slug: string): Observable<any> {
        return this.apiService.get$(environment.wizardBuilder, `/wizards`, { slug });
    }

    public getWizards$(dealId: string): Observable<any> {
        return this.apiService.get$(environment.wizardBuilder, '/wizards', { dealId });
    }

    public getAllRules$(versionID: string): Observable<IWizardRuleSet[]> {
        return this.apiService
            .get$(environment.wizardBuilder, `/wizard_versions/${versionID}/wizard_rule_sets`, { perPage: 9999 })
            .pipe(map((data: IPagination<IWizardRuleSet>) => data.result));
    }

    public getWizardWizardVersionRuleSets$(versionID: string, data: any): Observable<IPagination<IWizardRuleSet> | any> {
        return this.apiService.get$(environment.wizardBuilder, `/wizard_versions/${versionID}/wizard_rule_sets`, data);
    }

    public getControlLink$(continueID: string): Observable<any> {
        return this.apiService.get$(environment.wizardBuilder, `/continue_agreements/${continueID}/control_link`);
    }

    public getAdvancedControls$(versionID: string, type: string | string[] = ['math', 'custom', 'logic']) {
        let params = new HttpParams();

        params.set('pagination', 'false');
        if (Array.isArray(type)) {
            type.forEach((param) => {
                params = params.append('type[]', param);
            });
        } else {
            params.set('type', type);
        }

        return this.apiService
            .get$(environment.wizardBuilder, `/wizard_versions/${versionID}/wizard_element_controls`, params)
            .pipe(map((data: IPagination<IDocumentElementControl>) => data.result));
    }

    public getAllElements$(versionID: string, type?: string): Observable<IDocumentElement[]> {
        let params = new HttpParams();

        params = params.append('wizardVersion', versionID);
        params = params.append('perPage', '9999');
        if (type) {
            params = params.append('type', type);
        }

        return this.apiService
            .get$(environment.wizardBuilder, `/wizard_elements`, params)
            .pipe(map((data: IPagination<IDocumentElement>) => data.result));
    }

    public getAnswers$(agreementID: string): Observable<IWizardAnswer[]> {
        return this.apiService
            .get$(environment.wizardBuilder, `/agreements/${agreementID}/answers`, { perPage: 9999 })
            .pipe(map((data: IPagination<IWizardAnswer>) => data.result));
    }

    public getSectionAnswers$(params: any) {
        return this.apiService.get$(environment.wizardBuilder, `/wizard_element_section_answers`, params).pipe(map((data) => data.result));
    }

    public addAnswer$(data: any): Observable<IEntryResponse> {
        return this.apiService.post$(environment.wizardBuilder, '/entry', data);
    }

    public getAgreementById$(agreementID: string): Observable<IAgreementStatusInformation> {
        return this.apiService.get$(environment.wizardBuilder, `/agreements/${agreementID}`);
    }

    public submitAgreement$(agreementID: string, requestBody: any = {}): Observable<boolean> {
        return this.apiService.put$(environment.wizardBuilder, `/agreement/${agreementID}/submit`, requestBody);
    }

    public addAgreement$(data: any): Observable<string> {
        return this.apiService.post$(environment.wizardBuilder, '/agreements', data).pipe(map(({ id }) => id));
    }

    public patchAgreement$(
        agreementID: string,
        data: { userId?: string; wizardVersion?: string; createdAt?: Date; productId?: string; coreUserId?: number },
    ): Observable<any> {
        return this.apiService.put$(environment.wizardBuilder, `/agreements/${agreementID}`, data);
    }

    public getPdf$(htmlString: string): Observable<any> {
        return this.apiService.post$(
            null,
            environment.coreUrl + '/api/v4/pdfs',
            {
                html: htmlString,
            },
            null,
            null,
            'blob',
        );
    }

    public questionnaireLogin$(data: any): Observable<any> {
        return this.apiService.get$(environment.users, `/users/wb-access/${data.email}`);
    }

    public questionnaireRefreshToken$(refreshToken: string): Observable<any> {
        return this.apiService.post$(environment.users, '/token/refresh', { refresh_token: refreshToken });
    }

    public fetchWizardElementOptions$(wizardID: string, wizardVersionID: string, wizardSectionID: string, wizardElementID: string): Observable<any> {
        return this.apiService.get$(
            environment.wizardBuilder,
            `/wizards/${wizardID}/versions/${wizardVersionID}/wizard_sections/${wizardSectionID}/wizard_elements/${wizardElementID}/wizard_element_options`,
            { perPage: 9999 },
        );
    }

    public evaluateCustomControl$(controlId: string, agreementId: string): Observable<any> {
        return this.apiService.get$(environment.wizardBuilder, '/wizard_element_controls/evaluate/' + controlId + '/' + agreementId);
    }

    public fetchWizardVersionSchema$(versionId: string): Observable<IWizardVersionSchema> {
        return this.apiService.get$(environment.wizardBuilder, `/wizards/${versionId}/schema`);
    }

    public getAgreementInformation$(agreementID: string): Observable<IAgreementInformation> {
        return this.apiService
            .get$(environment.wizardBuilder, `/agreement_informations/`, { agreement: agreementID })
            .pipe(map((response) => response.result[0]));
    }

    public createAgreementInformation$(agreementID: string, information: IAgreementInformation): Observable<IAgreementInformation> {
        return this.apiService.post$(environment.wizardBuilder, '/agreement_informations', { agreement: agreementID, ...information });
    }

    public updateAgreementInformation$(informationID: string, information: IAgreementInformation): Observable<IAgreementInformation> {
        return this.apiService.put$(environment.wizardBuilder, `/agreement_informations/${informationID}`, information);
    }

    public getSummaryInformation$(agreementID: string): Observable<IAgreementSummarySection[]> {
        return this.apiService.get$(environment.wizardBuilder, `/agreement/${agreementID}/summary`).pipe(map((response) => response));
    }

    /**
     * Take all answers of a collection and remove them from a global answers array
     * using combination of id and collection number since collections created from add more
     * can have same id in their answers but different collection number
     * example case: User deletes a whole add more collection
     * @param answers - The global answers array
     * @param collectionAnswers - answers from the collection that was deleted
     * @returns answers minus collection's answers
     */
    public subtractFromAnswersArray(answers: IWizardAnswer[], collectionAnswers: IWizardAnswer[]): IWizardAnswer[] {
        return answers.filter(
            (answer) =>
                !collectionAnswers.find(
                    (collectionAnswer) =>
                        collectionAnswer.element + (collectionAnswer.collection || '') === answer.wizardElement?.id + (answer.collection || ''),
                ),
        );
    }

    public createContinueAgreement$(data: IContinueAgreementInfo): Observable<IContinueAgreementInfo> {
        return this.apiService.post$(environment.wizardBuilder, '/continue_agreements', data);
    }

    private doesElementContainsControl(element: IDocumentElement): boolean {
        const controlPattern = /<control(?:\s+(?:(?:id|title|reference|contenteditable|class)\s*=\s*"[^"]*"\s*)+){0,1}>.*?<\/control>/;

        if (controlPattern.test(element.name)) return true;
        else if (controlPattern.test(element.prefix)) return true;
        else if (element.wizardElementOptions.some((option) => controlPattern.test(option.value))) return true;
        else if (element.wizardElementAttributes.some((attr) => controlPattern.test(attr.value))) return true;

        return false;
    }
}
