import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Notification, NotificationType } from './notification.types';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    private subject$: Subject<Notification> = new Subject<Notification>();
    private id: number = 0;

    public info(message: string, timeout: number = 3000): void {
        this.subject$.next(new Notification(this.id++, NotificationType.info, message, timeout));
    }

    public success(message: string, timeout: number = 3000): void {
        this.subject$.next(new Notification(this.id++, NotificationType.success, message, timeout));
    }

    public warning(message: string, timeout: number = 3000): void {
        this.subject$.next(new Notification(this.id++, NotificationType.warning, message, timeout));
    }

    public error(message: string, timeout: number = 3000): void {
        this.subject$.next(new Notification(this.id++, NotificationType.error, message, timeout));
    }

    public getObservable$(): Observable<Notification> {
        return this.subject$.asObservable();
    }
}
