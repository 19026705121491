import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CountryEnv } from 'projects/questionnaire/src/lib/services/types';

@Injectable({
    providedIn: 'root',
})
export class ConstantsService {
    public readonly partnerStatuses = [
        { title: 'published', value: 'published' },
        { title: 'unpublished', value: 'unpublished' },
    ];

    public readonly countries = [
        { value: 'sweden', title: 'INPUTS.SWEDEN' },
        { value: 'germany', title: 'INPUTS.GERMANY' },
        { value: 'france', title: 'INPUTS.FRANCE' },
    ];
    public readonly languageCodesAndNames = [
        { name: 'Danish', code: 'da' },
        { name: 'English', code: 'en' },
        { name: 'Finnish', code: 'fi' },
        { name: 'Norwegian', code: 'no' },
        { name: 'Portuguese', code: 'pt' },
        { name: 'Swedish', code: 'sv' },
    ];

    public readonly states = [
        { name: 'AL', code: 'AL' },
        { name: 'AP', code: 'AP' },
        { name: 'AM', code: 'AM' },
        { name: 'BA', code: 'BA' },
        { name: 'CE', code: 'CE' },
        { name: 'DF', code: 'DF' },
        { name: 'ES', code: 'ES' },
        { name: 'GO', code: 'GO' },
        { name: 'MT', code: 'MT' },
        { name: 'MS', code: 'MS' },
        { name: 'MG', code: 'MG' },
        { name: 'PA', code: 'PA' },
        { name: 'PB', code: 'PB' },
        { name: 'PR', code: 'PR' },
        { name: 'PE', code: 'PE' },
        { name: 'PI', code: 'PI' },
        { name: 'RJ', code: 'RJ' },
        { name: 'RN', code: 'RN' },
        { name: 'RO', code: 'RO' },
        { name: 'RR', code: 'RR' },
        { name: 'SC', code: 'SC' },
        { name: 'SP', code: 'SP' },
        { name: 'SE', code: 'SE' },
        { name: 'TO', code: 'TO' },
    ];

    public readonly countryEnvs: CountryEnv[] = ['no', 'se', 'fi', 'com.br', 'br', 'dk'];
    public readonly countryAuthSource: Record<CountryEnv, 'crm' | 'core'> = {
        'se': 'core',
        'no': 'core',
        'fi': 'core',
        'com.br': 'core',
        'br': 'core',
        'dk': 'core',
    };
    public readonly defaultCountry: CountryEnv = 'se';
    public readonly coreAuthUrlAdmin = environment.coreUrl + '/admins/sign_in';
    public readonly coreUrl = environment.coreUrl;
    public readonly coreSignOutUrl = environment.coreUrl + '/users/sign_out';
    public readonly coreAuthUrlUser = environment.coreUrl + '/create_auth_url';
    public readonly coreApiUrl = environment.coreUrl + '/api/v4';
    public readonly crmAuthUrl = environment.crmUrl + '/admin';
    public readonly crmApiUrl = environment.crmUrl + '/api_crm';
    public readonly coreWizardCheckoutUrl = environment.coreUrl + '/wizard_checkout';
    public readonly crmWizardCheckoutUrl = environment.crmUrl + '/wizard_checkout';

    public readonly fontSizes = [
        { value: '8', title: '8pt' },
        { value: '9', title: '9pt' },
        { value: '10', title: '10pt' },
        { value: '11', title: '11pt' },
        { value: '12', title: '12pt' },
        { value: '14', title: '14pt' },
        { value: '16', title: '16pt' },
        { value: '18', title: '18pt' },
        { value: '20', title: '20pt' },
        { value: '22', title: '22pt' },
        { value: '24', title: '24pt' },
        { value: '26', title: '26pt' },
        { value: '28', title: '28pt' },
        { value: '30', title: '30pt' },
        { value: '36', title: '36pt' },
        { value: '48', title: '48pt' },
        { value: '72', title: '72pt' },
    ];

    public readonly textareaSizes = [
        { value: 'small', title: 'INPUTS.SMALL' },
        { value: 'medium', title: 'INPUTS.MEDIUM' },
        { value: 'large', title: 'INPUTS.LARGE' },
    ];

    public readonly dateFormats = [
        { value: 'yyyy-MM-dd', title: 'YYYY-MM-DD' },
        { value: 'yyyy/MM/dd', title: 'YYYY/MM/DD' },
        { value: 'dd-MM-yyyy', title: 'DD-MM-YYYY' },
        { value: 'dd/MM/yyyy', title: 'DD/MM/YYYY' },
        { value: 'MM-dd-yyyy', title: 'MM-DD-YYYY' },
        { value: 'MM/dd/yyyy', title: 'MM/DD/YYYY' },
        { value: 'dd.MM.yyyy', title: 'DD.MM.YYYY' },
        { value: 'MM.dd.yyyy', title: 'MM.DD.YYYY' },
        { value: 'yyy.MM.dd', title: 'YYYY.MM.DD' },
    ];

    // FYI DROPDOWN/LABEL doesnt need defaults
    public readonly defaultsForElements = [
        {
            type: 'TEXTAREA',
            attributes: [{ name: 'textareaSize', value: this.textareaSizes[0].value }],
            options: [],
        },
        {
            type: 'NUMBER',
            attributes: [
                { name: 'precision', value: '0' },
                { name: 'separator', value: ' ' },
                { name: 'decimalSeparator', value: '.' },
                { name: 'minValue', value: 'null' },
                { name: 'maxValue', value: 'null' },
                { name: 'minCharacters', value: 'null' },
                { name: 'maxCharacters', value: 'null' },
            ],
            options: [],
        },
        {
            type: 'DATE',
            attributes: [{ name: 'format', value: this.dateFormats[0].value }],
            options: [],
        },
        {
            type: 'RADIO_BUTTON',
            attributes: [{ name: 'style', value: 'list' }],
            options: [
                {
                    name: 'buttonName',
                    value: '',
                    order: 0,
                    attributes: [],
                },
            ],
        },
        {
            type: 'CHECKBOX',
            attributes: [{ name: 'style', value: 'list' }],
            options: [
                {
                    name: 'buttonName',
                    value: '',
                    order: 0,
                    attributes: [],
                },
            ],
        },

        {
            type: 'NAME',
            attributes: [
                { name: 'fieldsSize', value: '2' },
                {
                    name: 'fields',
                    value: JSON.stringify([
                        { name: '', tag: '', placeholder: '' },
                        { name: '', tag: '', placeholder: '' },
                    ]),
                },
            ],
            options: [],
        },

        {
            type: 'IMAGE',
            attributes: [],
        },
        {
            type: 'SECTION',
            attributes: [{ name: 'elementSize', value: 'full' }],
        },
        {
            type: 'SLIDER',
            attributes: [
                { name: 'value', value: '0' },
                { name: 'min', value: '0' },
                { name: 'max', value: '100' },
                { name: 'unit', value: '%' },
                {
                    name: 'lastUpdate',
                    get value() {
                        return Date.now().toString();
                    },
                },
            ],
        },
        {
            type: 'SSN',
            attributes: [
                { name: 'value', value: '0' },
                { name: 'country', value: 'sweden' },
                { name: 'placeholder', value: '' },
                { name: 'defaultPlaceholder', value: 'true' },
            ],
        },
    ];

    public readonly acceptedImageMimeTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
    public readonly acceptedDocumentMimeTypes = [
        'application/pdf', // pdf
        'application/msword', // doc
        'application/vnd.ms-excel', // xls
        'application/vnd.ms-powerpoint', // ppt
        'application/zip', // zip
        'text/csv', // csv
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
        'text/plain', // txt
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
        'application/vnd.oasis.opendocument.spreadsheet', // ods
        'application/rtf', // rtf
        'text/rtf', // rtf
        'text/tab-separated-values', // tsv
        'application/vnd.oasis.opendocument.text', // odt
    ];

    public readonly defaultAcceptedMimeTypes = [...this.acceptedImageMimeTypes, ...this.acceptedDocumentMimeTypes];

    public readonly acceptedEmailAttachmentMimeTypes = [
        ...this.acceptedImageMimeTypes,
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ];

    public readonly typeOfCustomers = [
        { value: false, title: 'INPUTS.PRIVATE' },
        { value: true, title: 'INPUTS.BUSINESS' },
    ];

    public readonly typeOfLeads = [
        { value: 'phone', title: 'INPUTS.PHONE' },
        { value: 'email', title: 'INPUTS.BY_EMAIL' },
    ];

    public readonly leadStatuses = [
        { value: 'handled', title: 'INPUTS.HANDLED' },
        { value: '2h', title: 'INPUTS.2_HOURS' },
        { value: '1d', title: 'INPUTS.1_DAY' },
        { value: '1m', title: 'INPUTS.1_MONTH' },
        { value: 'custom', title: 'INPUTS.CUSTOM' },
    ];

    public readonly leadFollowUp = [
        { value: 'asap', title: 'INPUTS.ASAP' },
        { value: 'specificTime', title: 'INPUTS.SPECIFIC_TIME' },
        { value: '8-20', title: '8-20' },
        { value: '8-12', title: '8-12' },
        { value: '12-17', title: '12-17' },
        { value: '17-20', title: '17-20' },
    ];

    public readonly typeOfActionOptions = [
        // { value: 'phone', title: 'INPUTS.PHONE' },
        { value: 'email', title: 'INPUTS.EMAIL' },
        // { value: 'video', title: 'INPUTS.VIDEO_MEETING' },
        // { value: 'physical', title: 'INPUTS.PHYSICAL_MEETING' },
        { value: 'incoming-call', title: 'INPUTS.INCOMING_CALL' },
        { value: 'outgoing-call', title: 'INPUTS.OUTGOING_CALL' },
        { value: 'whatsapp', title: 'INPUTS.WHATSAPP' },
        { value: 'widget', title: 'INPUTS.WIDGET' },
    ];

    public readonly defaultCustomerCardsOrder = [
        // BELOW IS HIDDEN FOR BRAZIL LAUNCH ONLY
        // 'leads',
        // 'cases',
        'recent-actions',
        'recent-agreements',
        'recently-received-emails',
        'created-carts',
        'nps',
        // 'autogenerated-messages',
        'files',
        'account-activity',
    ];

    public readonly leadSource = [
        { value: 'internal', title: 'INPUTS.INTERNAL' },
        { value: 'external', title: 'INPUTS.EXTERNAL' },
    ];

    public readonly conditions = [
        {
            name: 'INPUTS.EQUAL_TO',
            value: '==',
            displayValue: '=',
            excludedElements: [],
        },
        {
            name: 'INPUTS.NOT_EQUAL_TO',
            value: '!=',
            displayValue: '!=',
            excludedElements: [],
        },
        {
            name: 'INPUTS.LESS_THAN',
            value: '<',
            displayValue: '<',
            excludedElements: ['TEXTAREA', 'CHECKBOX'],
        },
        {
            name: 'INPUTS.GREATER_THAN',
            value: '>',
            displayValue: '>',
            excludedElements: ['TEXTAREA', 'CHECKBOX'],
        },
        {
            name: 'INPUTS.LESS_THAN_OR_EQUAL',
            value: '<=',
            displayValue: '<=',
            excludedElements: ['TEXTAREA', 'CHECKBOX'],
        },
        {
            name: 'INPUTS.GREATER_THAN_OR_EQUAL',
            value: '>=',
            displayValue: '>=',
            excludedElements: ['TEXTAREA', 'CHECKBOX'],
        },
        {
            name: 'INPUTS.INCLUDES',
            value: 'includes',
            displayValue: 'INPUTS.INCLUDES',
            excludedElements: ['TEXTAREA', 'RADIO_BUTTON', 'NAME', 'NUMBER', 'DROPDOWN', 'DATE'],
        },
        {
            name: 'INPUTS.NOT_INCLUDES',
            value: 'notIncludes',
            displayValue: 'INPUTS.NOT_INCLUDES',
            excludedElements: ['TEXTAREA', 'RADIO_BUTTON', 'NAME', 'NUMBER', 'DROPDOWN', 'DATE'],
        },
        {
            name: 'INPUTS.EMPTY',
            value: 'isEmpty',
            displayValue: 'INPUTS.EMPTY',
            noOption: true,
        },
        {
            name: 'INPUTS.NOT_ENDS_WITH',
            value: 'notEndsWith',
            displayValue: 'INPUTS.NOT_ENDS_WITH',
            excludedElements: ['RADIO_BUTTON', 'NUMBER', 'DROPDOWN', 'DATE'],
        },
        {
            name: 'INPUTS.NOT_EMPTY',
            value: 'isNotEmpty',
            displayValue: 'INPUTS.NOT_EMPTY',
            noOption: true,
        },
    ];

    public legalFieldsOptions = [
        { value: 'Bankruptcy', title: 'LEGAL_FIELDS.BANKRUPTCY' },
        { value: 'Estate', title: 'LEGAL_FIELDS.ESTATE' },
        { value: 'Debt', title: 'LEGAL_FIELDS.DEBT' },
        { value: 'Family Law', title: 'LEGAL_FIELDS.FAMILY_LAW' },
        { value: 'Family Law Dispute', title: 'LEGAL_FIELDS.FAMILY_LAW_DISPUTE' },
        { value: 'Corporate Law', title: 'LEGAL_FIELDS.CORPORATE_LAW' },
        { value: 'Child Custody', title: 'LEGAL_FIELDS.CHILD_CUSTODY' },
    ];

    public readonly radioDisplayStyles = [
        {
            value: 'list',
            title: 'INPUTS.LIST',
        },
        {
            value: 'sideBySide',
            title: 'INPUTS.SIDE_BY_SIDE',
        },
        {
            value: 'embedded',
            title: 'INPUTS.EMBEDDED',
        },
    ];

    public readonly labelDisplayStyles = [
        {
            value: 'default',
            title: 'INPUTS.DEFAULT',
        },
        {
            value: 'alert',
            title: 'INPUTS.ALERT',
        },
    ];

    public readonly alignments = [
        { value: 'left', name: 'INPUTS.LEFT' },
        { value: 'center', name: 'INPUTS.CENTER' },
        { value: 'right', name: 'INPUTS.RIGHT' },
    ];

    public readonly acceptImageFormats = ['image/png', 'image/jpeg', 'image/jpg'];

    public readonly acceptIconFormats = ['image/x-icon', 'image/png', 'image/svg+xml'];

    public readonly languages = [
        { value: 'en', name: 'LANGUAGES.ENGLISH' },
        { value: 'se', name: 'LANGUAGES.SWEDISH' },
        { value: 'fi', name: 'LANGUAGES.FINNISH' },
        { value: 'pt', name: 'LANGUAGES.PORTUGUESE' },
        { value: 'no', name: 'LANGUAGES.NORWEGIAN' },
    ];

    public get defaultLanguage(): string {
        return this.languages[0].value;
    }

    public readonly productTypes = [
        { value: 'online', title: 'INPUTS.ONLINE' },
        { value: 'lawyer', title: 'INPUTS.WITH_LAWYER' },
        { value: 'priceplan', title: 'INPUTS.PRICE_PLAN' },
    ];

    public readonly weekDays = [
        { value: 'Monday', name: 'INPUTS.MONDAY' },
        { value: 'Tuesday', name: 'INPUTS.TUESDAY' },
        { value: 'Wednesday', name: 'INPUTS.WEDNESDAY' },
        { value: 'Thursday', name: 'INPUTS.THURSDAY' },
        { value: 'Friday', name: 'INPUTS.FRIDAY' },
        { value: 'Saturday', name: 'INPUTS.SATURDAY' },
        { value: 'Sunday', name: 'INPUTS.SUNDAY' },
    ];

    public readonly timeFormats: { title: string; value: string }[] = [
        {
            title: 'INPUTS.24_HOUR',
            value: '24',
        },
        {
            title: 'INPUTS.12_HOUR',
            value: '12',
        },
    ];

    public readonly calendarDateFormats = [
        { value: 'Y-m-d', title: 'YYYY-MM-DD', code: 'sv' },
        { value: 'd/m/Y', title: 'DD/MM/YYYY', code: 'pt' },
        { value: 'm/d/Y', title: 'MM/DD/YYYY', code: 'en' },
    ];

    public readonly eventTypes: { title: string; value: string }[] = [
        {
            title: 'INPUTS.BB',
            value: 'bb',
        },
        {
            title: 'INPUTS.BA',
            value: 'ba',
        },
        {
            title: 'INPUTS.INTERNAL',
            value: 'internal',
        },
        {
            title: 'INPUTS.PERSONAL',
            value: 'personal',
        },
        {
            title: 'INPUTS.OTHER',
            value: 'other',
        },
    ];

    public readonly recurringTypes: { title: string; value: string }[] = [
        {
            title: 'INPUTS.DOES_NOT_REPEAT',
            value: 'does_not_repeat',
        },
    ];

    public readonly meetingMethods: { title: string; value: string }[] = [
        {
            title: 'INPUTS.PHONE',
            value: 'phone',
        },
        {
            title: 'INPUTS.FACE_TO_FACE',
            value: 'face-to-face',
        },
        {
            title: 'INPUTS.VIDEO',
            value: 'video',
        },
    ];

    public readonly partnerFormDateFormats: { name: string; value: string }[] = [
        { name: 'yyyy-mm-dd', value: 'yyyy-MM-dd' },
        { name: 'yyyy/mm/dd', value: 'yyyy/MM/dd' },
        { name: 'dd-mm-yyyyy', value: 'dd-MM-yyyy' },
        { name: 'dd/mm/yyyy', value: 'dd/MM/yyyy' },
        { name: 'mm-dd-yyyy', value: 'dd-DD-yyyy' },
        { name: 'mm/dd/yyyy', value: 'dd/DD/yyyy' },
        { name: 'dd.mm.yyyy', value: 'dd.MM.yyyy' },
        { name: 'mm.dd.yyyy', value: 'dd.DD.yyyy' },
        { name: 'yyyy.mm.dd', value: 'yyyy.MM.DD' },
    ];

    public readonly scheduleTypes: { title: string; value: string }[] = [
        {
            title: 'INPUTS.MY_SCHEDULE',
            value: 'my_schedule',
        },
    ];

    public readonly dateRangeTypes: { title: string; value: string }[] = [
        {
            title: 'INPUTS.WEEK',
            value: 'week',
        },
        {
            title: 'INPUTS.DAY',
            value: 'day',
        },
        {
            title: 'INPUTS.CUSTOM',
            value: 'custom',
        },
    ];

    public readonly global = [
        { title: 'Yes', value: 'true' },
        { title: 'No', value: 'false' },
    ];

    public readonly categoryType = [
        { value: 'Cancellation', id: 'cancellation' },
        { value: 'Rejection', id: 'rejection' },
        { value: 'Subject matter', id: 'subject_matter' },
    ];

    public readonly translations = [
        { name: 'Swedish', shortcode: 'sv' },
        { name: 'Danish', shortcode: 'da' },
        { name: 'Finnish', shortcode: 'fi' },
        { name: 'English', shortcode: 'en' },
        { name: 'Norwegian', shortcode: 'no' },
    ];

    public readonly lawAreaType = [
        { value: null, name: 'None' },
        { value: 'private', name: 'Private' },
        { value: 'business', name: 'Business' },
    ];

    /**
     * Monkey patch
     * Todo: Remove this translation alternative once
     * we implement the feature to allow summary page content
     * to be modified from agreement builder
     */

    public readonly SUMMARY_CONTENT_TEMP = {
        WIZARD_IDS: ['ec84543c-4930-4073-949f-acdfe163f8e6'],
        QUESTIONNAIRE_BLURB:
            'Du kan omedelbart ladda ner dokumentet efter att du har betalat för den. Efter att du köpt dokumentet får du per epost noggrannare anvisningar för undertecknandet av dokumentet.',
        QUESTIONNAIRE_COMPLETE: 'Tack, Ditt avtal är färdigställt!',
        VALUE_PROP_1:
            'Vill du göra ändringar till ditt dokument? Du kan redigera dokumentet som uppgjorts på nätet avgiftsfritt inom 14 dygn från köpet..',
        VALUE_PROP_2:
            'För oss är det viktigt att du är nöjd. Vår kundservice står till tjänst vardagar 10.00-16.30. Du får dina pengar tillbaka om du är missnöjd med vår tjänst.',
        VALUE_PROP_3:
            'Alla dokument som går att upprätta i vår onlinetjänst är skapade av våra jurister. Du kan lita på att dokumentet uppfyller alla formkrav.',
        TITLE_PROP_1: '14 dagars kostnadsfri ändringsrätt',
        TITLE_PROP_2: 'Nöjdhetsgaranti',
        TITLE_PROP_3: 'Trygg onlinetjänst skapad av jurister',
        SUPPORT_NUMBER: '010 3379 150',
        OFFICE_HOURS_VALUE: '10.00 - 16.30',
    };
}
