import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CountryEnv } from 'projects/questionnaire/src/lib/services/types';
import { Observable } from 'rxjs';

import { NotificationsService } from './../../../../../notifications/src/lib/notifications.service';
import { ConstantsService } from './constants.service';

export type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'delete';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(private http: HttpClient, private notificationService: NotificationsService, private constantService: ConstantsService) {}

    public getCountry(): CountryEnv {
        const hrefParts = location.host.split('.');
        const country = hrefParts.find((word) => this.constantService.countryEnvs.some((code) => word.includes(code))) as CountryEnv;

        if (!country) {
            this.notificationService.error('API_ERRORS.INVALID_COUNTRY');
        }

        return country;
    }

    public get$(microservice: string, path: string, data?: any): Observable<any> {
        return this.http.request('get', this.buildPath(path, microservice), {
            params: data,
            withCredentials: true,
        });
    }

    public post$(microservice: string, path: string, data: any | FormData, query?: any, headers?: any, responseType?: any): Observable<any> {
        return this.http.request('post', this.buildPath(path, microservice), {
            body: data,
            params: query,
            headers,
            ...(responseType ? { responseType } : {}),
        });
    }

    public postWithHeaders$(microservice: string, path: string, data: any | FormData, query?: any, headers?: any): Observable<any> {
        return this.http.request('post', this.buildPath(path, microservice), {
            body: data,
            params: query,
            headers,
        });
    }

    public put$(microservice: string, path: string, data: any | FormData, query?: any): Observable<any> {
        return this.http.request('put', this.buildPath(path, microservice), { body: data, params: query });
    }

    public patch$(microservice: string, path: string, data: any | FormData, query?: any): Observable<any> {
        return this.http.request('patch', this.buildPath(path, microservice), { body: data, params: query });
    }

    public delete$(microservice: string, path: string, data?: any): Observable<any> {
        return this.http.request('delete', this.buildPath(path, microservice), { body: data });
    }

    public download$(microservice: string, path: string): Observable<any> {
        return this.http.request('get', this.buildPath(path, microservice), { responseType: 'blob' });
    }

    public upload$(microservice: string, path: string, file: File, data?: object): Observable<any> {
        const formData = new FormData();
        const params = new HttpParams();
        const options = {
            params,
            reportProgress: true,
            observe: 'events',
        };

        if (data) {
            Object.keys(data).forEach((key) => formData.append(key, data[key]));
        }
        formData.append('file', file);
        const req = new HttpRequest('post', this.microUrl.replace('MICROSERVICE', microservice) + path, formData, options);

        return this.http.request(req);
    }

    public getFromCrm$(path: string): Observable<any> {
        return this.http.request('get', this.constantService.crmApiUrl + path, { withCredentials: true });
    }

    public postToCrm$(path: string, data: any | FormData, query?: any): Observable<any> {
        return this.http.request('post', this.constantService.crmApiUrl + path, { withCredentials: true, body: data, params: query });
    }

    public populateUrl(microService: string, url = this.microUrl): string {
        url = url.replace('[MICROSERVICE]', microService);
        url = url.replace('[COUNTRY]', this.getCountry() || this.constantService.defaultCountry);

        return url;
    }

    private buildPath(path, microService?: string): string {
        return this.populateUrl(microService, path.indexOf('http') > -1 ? path : this.microUrl + path);
    }

    private get microUrl() {
        const country = this.getCountry();
        let microUrl = '';

        switch (country) {
            case 'se':
                microUrl = environment.microUrl_se;
                break;
            case 'no':
                microUrl = environment.microUrl_no;
                break;
            case 'fi':
                microUrl = environment.microUrl_fi;
                break;
            case 'com.br':
            case 'br':
                microUrl = environment.microUrl_br;
                break;
            case 'dk':
                microUrl = environment.microUrl_dk;
                break;
            default:
                microUrl = environment.microUrl_se;
                break;
        }

        return microUrl;
    }

    public get cmsUrl() {
        const country = this.getCountry();
        let cmsUrl = '';

        switch (country) {
            case 'se':
                cmsUrl = environment.cmsUrl_se;
                break;
            case 'no':
                cmsUrl = environment.cmsUrl_no;
                break;
            case 'fi':
                cmsUrl = environment.cmsUrl_fi;
                break;
            case 'com.br':
            case 'br':
                cmsUrl = environment.cmsUrl_br;
                break;
            case 'dk':
                cmsUrl = environment.cmsUrl_dk;
                break;
            default:
                cmsUrl = environment.cmsUrl_se;
                break;
        }

        return cmsUrl;
    }
}
