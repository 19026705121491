import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from '@sentry/angular';
import { environment } from 'environments/environment';
import { EuiAuthorizationState } from 'projects/end-user-interface/src/app/modules/store/state';
import { NotificationsModule } from 'projects/notifications/src/lib/notifications.module';
import { AnswersState } from 'projects/questionnaire/src/lib/store/answers/answers.state';
import { ToolsModule } from 'projects/tools/src/lib/tools.module';

import { AppComponent } from './app.component';
import { AppRouter } from './app.router';
import { MainInterceptor } from './core/interceptors/main.interceptor';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient, injector: Injector) {
    return new TranslateHttpLoader(http);
}

// tslint:disable-next-line: max-classes-per-file
@NgModule({
    declarations: [AppComponent],
    imports: [
        AppRouter,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient, Injector],
            },
        }),
        NgxsModule.forRoot([EuiAuthorizationState, AnswersState], {
            developmentMode: !environment.production,
        }),
        ToolsModule,
        NotificationsModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MainInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    entryComponents: [AppComponent],
    bootstrap: [AppComponent],
})
export class AppModule {}
