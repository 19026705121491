<div class="notifications">
    <div *ngFor="let notification of notifications" @notificationAnimation class="notification">
        <div [ngSwitch]="notification.type" class="icon">
            <img *ngSwitchCase="'success'" src="/assets/icons/success-message.svg" alt="" />
            <img *ngSwitchCase="'warning'" src="/assets/icons/warning-message.svg" alt="" />
            <img *ngSwitchCase="'error'" src="/assets/icons/error-message.svg" alt="" />
            <img *ngSwitchCase="'info'" src="/assets/icons/info-message.svg" alt="" />
            <img *ngSwitchDefault src="/assets/icons/info-message.svg" alt="" />
        </div>
        <div>{{ notification.message | translate }}</div>
        <div (click)="close(notification.id)" class="close"><img src="/assets/icons/close.svg" alt="" /></div>
    </div>
</div>