import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { environment } from 'environments/environment';

import { AppModule } from './app/app.module';
import { DEPLOY_URL } from './deploy-url';

Sentry.init({
    dsn: 'https://b025269cd3c2f91208fd8c230bce8834@o4507033217269760.ingest.de.sentry.io/4507174955581520',
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /^https:\/\/micro\.se\.test\.lexly\.io/],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new Sentry.Replay(),
    ],
    environment: environment.isProduction ? 'PROD' : 'DEV',
    sampleRate: 1,
    // Performance Monitoring
    tracesSampleRate: 1, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
