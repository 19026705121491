import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { IJwtInfo, LoggedService } from 'projects/mission-control/src/app/modules/logged/logged.service';
import { QuestionnaireService } from 'projects/questionnaire/src/lib/services/questionnaire.service';
import { ApiService } from 'projects/tools/src/lib/core/services/api.service';
import { interval, noop, Subject } from 'rxjs';
import { switchMap, takeWhile, tap } from 'rxjs/operators';

import { LoginViaBankId, Logout, PollBankIDLoginStatus, RefreshToken, UpdateLoginStatus } from './actions';
import { EuiService } from './service';
import { IBankIdLoginResponse, IBankIdPollResponse, IEuiAuthorizationState, IUserInfo } from './types';

@State<IEuiAuthorizationState>({
    name: 'euiAuthorization',
    defaults: {
        user_info: null,
        accounts: null,
    },
})
@Injectable()
export class EuiAuthorizationState {
    private readonly POLL_INTERVAL: number = 4000;

    constructor(
        private euiService: EuiService,
        private apiService: ApiService,
        private loggedService: LoggedService,
        private questionnaireService: QuestionnaireService,
    ) {}

    @Selector()
    static user(state: IEuiAuthorizationState) {
        return state.user_info;
    }

    @Selector()
    static accounts(state: IEuiAuthorizationState) {
        return state.accounts;
    }

    @Action(UpdateLoginStatus)
    UpdateLoginStatus(ctx: StateContext<IEuiAuthorizationState>, action: UpdateLoginStatus) {
        const country = this.apiService.getCountry();

        switch (country) {
            case 'se':
                return this.loggedService.checkJwt$().pipe(
                    tap(
                        (data: IJwtInfo) => {
                            if (data) {
                                ctx.patchState({
                                    user_info: data,
                                    accounts: [],
                                });
                            }
                        },
                        () => {
                            ctx.dispatch(new RefreshToken());
                        },
                    ),
                );
            default:
                return this.loggedService.checkJwt$().pipe(
                    tap(
                        (data: IJwtInfo) => {
                            if (data) {
                                ctx.patchState({
                                    user_info: data,
                                    accounts: [],
                                });
                            }
                            if (action.agreementId && data.coreUserId) {
                                this.questionnaireService
                                    .patchAgreement$(action.agreementId, { coreUserId: data.coreUserId, wizardVersion: action.wizardVersion })
                                    .subscribe(noop);
                            }
                        },
                        () => {
                            ctx.dispatch(new RefreshToken());
                        },
                    ),
                );
        }
    }

    @Action(LoginViaBankId)
    LoginViaBankId(ctx: StateContext<IEuiAuthorizationState>, action: LoginViaBankId) {
        return this.euiService
            .loginViaBankId$(action.form)
            .pipe(tap((data: IBankIdLoginResponse) => ctx.dispatch(new PollBankIDLoginStatus({ orderRef: data.orderRef }))));
    }

    @Action(PollBankIDLoginStatus)
    PollBankIDLoginStatus(ctx: StateContext<IEuiAuthorizationState>, action: PollBankIDLoginStatus) {
        return interval(this.POLL_INTERVAL).pipe(
            switchMap(() => this.euiService.pollBankIDLoginStatus$(action.form)),
            takeWhile((data: IBankIdPollResponse) => {
                if (data.status === 'success') {
                    ctx.patchState({
                        user_info: data.user_info,
                        accounts: data.accounts,
                    });

                    return false;
                }

                return true;
            }),
        );
    }

    @Action(Logout)
    Logout(ctx: StateContext<IEuiAuthorizationState>) {
        return this.euiService.logoutFromCrm$().pipe(tap(() => ctx.patchState({ user_info: null, accounts: null })));
    }

    @Action(RefreshToken)
    RefreshToken(ctx: StateContext<IEuiAuthorizationState>) {
        return this.loggedService.refresh$().pipe(
            tap(
                () => {},
                () => ctx.patchState({ user_info: null, accounts: null }),
            ),
        );
    }
}
