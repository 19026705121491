import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { IBankIdLoginRequest, IBankIdLoginResponse, IBankIdPollRequest, IBankIdPollResponse, IUserInfo } from 'projects/end-user-interface/src/app/modules/store/types';
import { IWizard } from 'projects/mission-control/src/app/modules/logged/wizard-builder/store/types';
import { ApiService } from 'projects/tools/src/lib/core/services/api.service';
import { IPagination } from 'projects/tools/src/lib/shared/interfaces/pagination.interface';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EuiService {
    constructor(
        private apiService: ApiService,
    ) { }

    public getPublishedWizards$(): Observable<IPagination<IWizard>> {
        return this.apiService.get$(environment.wizardBuilder, `/wizards`, { page: 1, perPage: 30, status: 'in_production'});
    }

    public getLoginStatus$(): Observable<IUserInfo> {
        return this.apiService.getFromCrm$('/user_info');
    }

    public loginViaBankId$(data: IBankIdLoginRequest): Observable<IBankIdLoginResponse> {
        return this.apiService.postToCrm$('/bankid_login', data);
    }

    public pollBankIDLoginStatus$(data: IBankIdPollRequest): Observable<IBankIdPollResponse> {
        return this.apiService.postToCrm$('/bankid_poll', data);
    }

    public logoutFromCrm$(): Observable<any> {
        return this.apiService.getFromCrm$('/logout');
    }

    public refreshTokenFromCrm$(): Observable<any> {
        return this.apiService.getFromCrm$('/refresh');
    }
}
