import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-end-user-interface',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.setLanguage();
  }

  private setLanguage(): void {
    const lang = localStorage.getItem('language');

    if (lang) {
      this.translateService.use(lang);
    } else {
      this.translateService.use('en');
    }
  }
}
