import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./modules/logged.module').then(({ LoggedModule }) => LoggedModule) },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes, {preloadingStrategy: PreloadAllModules},
  )],
  exports: [RouterModule],
})
export class AppRouter { }
