import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { ApiService } from './api.service';

export interface ITokenData {
    exp: number;
    iat: number;
    userId: string;
    organizationId: string;
    organizationTenantId: string;
    status: string;
    username: string;
    firstName: string;
    lastName: string;
    roles: { [id: string]: string };
}

export const TOKEN = 'token';
export const REFRESH_TOKEN = 'refreshToken';

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    constructor(private apiService: ApiService) {}
    public getToken(): string {
        return localStorage.getItem(TOKEN);
    }

    public saveToken(token: string): void {
        localStorage.setItem(TOKEN, token);
    }

    public removeToken(): void {
        localStorage.removeItem(TOKEN);
    }

    public getTokenData(): ITokenData {
        return null;
    }

    public getRefreshToken(): string {
        return localStorage.getItem(REFRESH_TOKEN);
    }

    public saveRefreshToken(refreshToken: string): void {
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
    }

    public removeRefreshToken(): void {
        localStorage.removeItem(REFRESH_TOKEN);
    }
}
