import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { notificationAnimation } from './notification.animation';
import { Notification } from './notification.types';
import { NotificationsService } from './notifications.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    animations: [notificationAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit, OnDestroy {
    public notifications: Notification[] = [];
    private subscription$: Subscription;

    constructor(
        private notificationsService: NotificationsService,
        private cdr: ChangeDetectorRef,
    ) { }

    public ngOnInit(): void {
        this.subscription$ = this.notificationsService.getObservable$()
            .subscribe((notification: Notification) => this.add(notification));
    }

    public ngOnDestroy(): void {
        if (this.subscription$) {
            this.subscription$.unsubscribe();
        }
    }

    public close(id: number): void {
        this.notifications = this.notifications.filter((n) => n.id !== id);
        this.cdr.detectChanges();
    }

    private add(notification: Notification): void {
        this.notifications.push(notification);
        setTimeout(() => this.close(notification.id), notification.timeout);
        this.cdr.detectChanges();
    }
}
