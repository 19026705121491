import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggedService } from 'projects/end-user-interface/src/app/modules/logged.service';
import { NotificationsService } from 'projects/notifications/src/lib/notifications.service';
import { TokenService } from 'projects/tools/src/lib/core/services/token.service';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class MainInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private tokenRefreshedSource$ = new Subject();
    private tokenRefreshed$ = this.tokenRefreshedSource$.asObservable();
    private headers: { [name: string]: string | string[] } = {};

    constructor(private tokenService: TokenService, private notificationsService: NotificationsService, private loggedService: LoggedService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.addHeaders(request);

        return next.handle(request.clone({ setHeaders: this.headers, withCredentials: true })).pipe(
            catchError((err: any) => {
                return this.handleErrorResponse$(err, request, next);
            }),
        );
    }

    private handleErrorResponse$(err, request?, next?): Observable<any> {
        if (this.isRefreshError(err)) {
            return throwError(err);
        } else if (err.status >= 500) {
            this.notificationsService.error('API_ERRORS.INTERNAL_SERVER_ERROR');
        } else if (this.is401Error(err)) {
            this.refreshToken$().pipe(
                switchMap(() => {
                    this.addHeaders(request);

                    return next.handle(request);
                }),
                catchError((error) => {
                    if (error.status !== 401) {
                        return this.handleErrorResponse$(error);
                    } else {
                        return throwError(error);
                    }
                }),
            );
        } else if (err.error) {
            const code = this.getErrorCode(err);

            if (code) {
                this.notificationsService.warning(`API_ERRORS.${code}`);
            }
        }

        return throwError(err);
    }

    private refreshToken$(): Observable<any> {
        if (this.refreshTokenInProgress) {
            return new Observable((observer) => {
                this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;

            return this.loggedService.refresh$().pipe(
                tap(() => {
                    this.refreshTokenInProgress = false;
                    this.tokenRefreshedSource$.next();
                }),
                catchError((err) => {
                    this.refreshTokenInProgress = false;

                    return throwError(err);
                }),
            );
        }
    }

    private addHeaders(request: HttpRequest<any>): void {
        this.setAcceptHeader(request);
        this.setContentTypeHeader(request);
        this.setAuthorizationHeader(request);
    }

    private setAcceptHeader(request: HttpRequest<any>): void {
        this.headers['Accept'] = request.responseType === 'json' ? 'application/json' : 'application/json, text/plain, */*';
    }

    private setContentTypeHeader(request: HttpRequest<any>): void {
        const contentType = request.headers.get('Content-Type');

        if (contentType) {
            this.headers['Content-Type'] = contentType;
        } else if (request.body instanceof FormData) {
            delete this.headers['Content-Type'];
        } else {
            this.headers['Content-Type'] = 'application/json';
        }
    }

    private setAuthorizationHeader(request: HttpRequest<any>): void {
        const token = this.tokenService.getToken();

        if (
            !token ||
            // s3 file upload exclusion
            (request.method === 'POST' && /^https:\/\/s3/.test(request.url)) ||
            (request.method === 'GET' && request.url.includes('/assets/i18n/')) ||
            // exclude Authorization header (example use -> for pdf fetching)
            request.headers.get('Skip')
        ) {
            delete this.headers.Authorization;
        } else {
            this.headers.Authorization = `Bearer ${token}`;
        }
    }

    private isRefreshError({ url }: HttpErrorResponse): boolean {
        return url?.includes?.('/refresh') ?? false;
    }

    private is401Error({ status, error }: HttpErrorResponse): boolean {
        return status === 401;
    }

    private getErrorCode({ error }: HttpErrorResponse): string {
        return error?.error_key || error?.violations?.[0]?.error_key;
    }
}
