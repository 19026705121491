import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationsComponent } from './notifications.component';

@NgModule({
    declarations: [
        NotificationsComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
    ],
    providers: [],
    exports: [
        NotificationsComponent,
    ],
})
export class NotificationsModule { }
