import { IAnswersEntryModel } from './answers.model';

// tslint:disable:max-classes-per-file
export class AddAnswers {
    static readonly type = '[Answer] Add Answers';
    constructor(public entry: IAnswersEntryModel) {}
}

export class ClearAnswers {
    static readonly type = '[Answer] Clear Answers';
}
