import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { IEntryResponse } from 'projects/mission-control/src/app/modules/logged/wizard-builder/store/types';
import { tap } from 'rxjs/operators';

import { QuestionnaireService } from '../../services/questionnaire.service';

import { AddAnswers, ClearAnswers } from './answers.actions';
import { IAnswersEntryModel, IAnswersStateModel } from './answers.model';

@State<IAnswersStateModel>({
    name: 'answers',
    defaults: {
        entry: null,
        response: null,
    },
})
@Injectable()
export class AnswersState {
    constructor(private questionnaireService: QuestionnaireService) {}

    @Action(AddAnswers)
    addAnswers(ctx: StateContext<IAnswersStateModel>, action: { entry: IAnswersEntryModel }) {
        const state = ctx.getState();

        return this.questionnaireService.addAnswer$(action.entry).pipe(
            tap({
                next: (response) => {
                        ctx.setState({
                            entry: action.entry,
                            response,
                        });
                },
                error: (error) => { console.error(error); },
            }),
        );
    }

    @Action(ClearAnswers)
    clearAnswers(ctx: StateContext<IAnswersStateModel>) {
        ctx.patchState({
            entry: null,
            response: null,
        });
    }
}
