export const environment = {
  production: true,
  wsEndpoint: 'wss://kgfgb1ue39.execute-api.eu-north-1.amazonaws.com/live',
  microUrl_se: 'https://micro.lexly.se/[MICROSERVICE]',
  microUrl_fi: 'https://micro.lexly.fi/[MICROSERVICE]',
  microUrl_no: 'https://micro.lexly.no/[MICROSERVICE]',
  microUrl_br: 'https://micro.lexly.com.br/[MICROSERVICE]',
  microUrl_dk: 'https://micro.lexly.dk/[MICROSERVICE]',
  uploadCompletedUrl: 'upload-completed',
  presignedPostUrl: 'presigned-post',
  agreements: 'api-agreements',
  users: 'api-users',
  configurator: 'api-my-agreements-configurator',
  files: 'api-files',
  search: 'api-search',
  nps: 'api-nps',
  notifications: 'api-notification',
  wizardBuilder: 'api-wizard-builder',
  scheduler: 'api-scheduler',
  crm: 'api-crm',
  checkout: 'api-checkout',
  calendar: 'api-calendar',
  productModule: 'api-product',
  partnerModule: 'api-partner',
  jsdKey: '',
  version: '',
  authUrl: '',
  crmUrl: 'https://crm.lexly.se',
  coreUrl: 'https://app.lexly.[COUNTRY]',
  isProduction: 'true',
  cmsUrl_se: 'https://lexly.se',
  cmsUrl_fi: 'https://lexly.fi',
  cmsUrl_no: 'https://lexly.no',
  cmsUrl_br: 'https://lexly.com.br',
  cmsUrl_dk: 'https://lexly.dk',
};
